/* Add this CSS to your styles or import a CSS file */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    transform: scale(0.8);
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 100px;
    /* border-radius: ; */
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 18px;
    left: 4px;
    bottom: 2.5px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 100px;
}

input:checked+.slider {
    background-color: var(--theme-color-one);
}

input:focus+.slider {
    box-shadow: 0 0 1px var(--theme-color-one);
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}