.item-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.item-image-container .thumbnail {
    max-width: 100px;
    height: 120px;
    object-fit: contain;
}

.item-image-container .tags {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}