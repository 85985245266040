.checkbox {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    outline: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease-in-out 0.1s;
}

.checkbox.checked {
    border: none;
    background: var(--theme-color-one);
}

.checkbox.un-checked {
    /* background: white; */
    /* border: 1px solid gray; */
    
    border: none;
    background: var(--theme-color-one);
}

.checkbox .icon {
    color: white;
    font-size: 11px;
}