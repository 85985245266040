
#cm {
    border-radius: 0;
    color: var(--text-gray);
}

button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
    cursor: pointer;
}
.cc_div .cc-link {
    border-bottom: 1px solid var(--theme-color-one);
    border-bottom: 1px solid var(--theme-color-one);
    color: var(--theme-color-one);
    color: var(--theme-color-one);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    padding-bottom: 0;
    text-decoration: none;
}

#c-bns button:first-child, #s-bns button:first-child {
    background: var(--theme-color-one);
    color: #fff;
    color: var(--cc-btn-primary-text);
    border-radius: 0px;
}

#c-bns button:first-child:hover, #s-bns button:first-child:hover {
    background: var(--bg-gray);
    color: #fff;
    color: var(--cc-btn-primary-text);
}

#c-s-bn {
    background-color: lightgray;
    color: var(--text-gray);
}

#c-s-bn:hover {
    background-color: lightgray;
    color: var(--text-gray);
}

#s-rall-bn {
    background-color: lightgray;
    color: var(--text-gray);
}

#s-sv-bn {
    background-color: lightgray;
    color: var(--text-gray);
}

#s-rall-bn:hover {
    background-color: lightgray;
    color: var(--text-gray);
}

#s-sv-bn:hover {
    background-color: lightgray;
    color: var(--text-gray);
}

#cm .c_link:active, #cm .c_link:hover, #s-c-bn:active, #s-c-bn:hover, #s-cnt button+button:active, #s-cnt button+button:hover {
    background: #d8e0e6;
    color: var(--cc-btn-secondary-hover-text);
    background-color: lightgray;
    color: var(--text-gray);
}

#s-inr {
    border-radius: 0px;
    color: var(--text-gray);
}

#s-cnt .b-bn .b-tl {
    background: lightgray;
    display: block;
    font-family: inherit;
    font-size: .95em;
    padding: 1.3em 6.4em 1.3em 2.7em;
    position: relative;
    transition: background-color .25s ease;
    width: 100%;
    border-radius: 0px;
}

#s-cnt:hover .b-bn .b-tl:hover {
    background: lightgray;
    display: block;
    font-family: inherit;
    font-size: .95em;
    padding: 1.3em 6.4em 1.3em 2.7em;
    position: relative;
    transition: background-color .25s ease;
    width: 100%;
}

#s-bl .b-acc .p {
    margin-top: 0;
    padding: 1em;
}
#s-cnt .p {
    color: var(--text-gray);
    font-size: .9em;
    line-height: 1.5em;
    margin-top: .85em;
}

#s-c-bn {
    background: lightgray;
    color: var(--text-gray);
    font-size: 1.45em;
    font-weight: 400;
    height: 1.7em;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 1.7em;
}

#c-bns button+button, #s-c-bn, #s-cnt button+button {
    float: right;
    margin-left: .5rem;
    border-radius: 0px;
}

#c-bns button+button, #s-c-bn, #s-cnt button+button:hover {
    background-color: var(--bg-gray-btn-hover);
}